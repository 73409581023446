import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Avatar,
  Grid,
  Box,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import Logo from "src/component/Logo";
import SettingsContext from "src/context/SettingsContext";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: "7px 30px 7px 30px",
    background: "#161618",
    // background: theme.palette.background.header,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 0px 20px",
    },
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& svg": {
      color: theme.palette.text.primary,
      "@media(max-width:767px)": {
        fontSize: "18px",
      },
    },
    "& .leftBox": {
      width: "100%",
      maxWidth: "306px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "150px",
      },
      "& img": {
        width: "100%",
        maxWidth: "110px",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "80px",
          paddingLeft: "0 !important",
          paddingTop: "8px",
          paddingBottom: "8px",
        },
        "@media(max-width:483px)": {
          maxWidth: "58px",
        },
      },
    },
  },
  avatarmain: {
    marginLeft: "16px",
    "@media(max-width:767px)": {
      marginLeft: "6px",
    },
  },
}));

const TopBar = ({
  selectedTab,
  onTabChange,
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const userData = auth.userData;

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      style={{ boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)" }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ padding: "0px" }}
          >
            <SvgIcon>
              <MenuIcon style={{ color: "#ff6600", fontSize: "25px" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        &nbsp; &nbsp;
        <Box className={classes.mainheader}>
          <Grid container alignItems="center">
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <Box className="leftBox">
                <Logo width="125" />
              </Box>
            </Grid>
            <Hidden xsDown>
              <Grid lg={4} md={6} sm={5}></Grid>
            </Hidden>
            <Grid lg={5} md={3} sm={3} xs={6}>
              {/* <Box className="displayEnd">
                <Box
                  className={classes.avatarmain}
                  onClick={() => history.push("/profile")}
                  style={{ cursor: "pointer" }}
                >
                  <Avatar
                    src={
                      userData?.profilePic
                        ? userData?.profilePic
                        : "images/editprofile.png"
                    }
                  />
                </Box>
              </Box> */}
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Hidden xsDown>
          <Box>
            <Typography variant="h5">NFT Marketplace</Typography>
            <Typography variant="body1" style={{ color: "#ffffff9c" }}>
              example@gmail.com
            </Typography>
          </Box>
        </Hidden>
        &nbsp; &nbsp;
        <Avatar
          src={
            auth?.userData?.profilePic
              ? `${auth?.userData?.profilePic}`
              : "https://picsum.photos/533/357"
          }
          className={classes.avatar}
          // onClick={() => history.push("/admin-profile")}
        />
      </Box>
    </>
  );
}

const url = "https://nodevalidatorpune-cryptowallet.mobiloitte.io"; // staging
// const url = "https://nodevalidator.chromescan.org"; //live

const ApiConfig = {
  // login: `${url}/api/v1/user/login`,
  loginadmin: `${url}/api/v1/admin/login`,
  // signup: `${url}/api/v1/user/register`,
  // verify: `${url}/api/v1/user/verify`,
  verifyOTP: `${url}/api/v1/admin/verify-OTP`,
  // forgot: `${url}/api/v1/user/forgot`,
  forgotPassword: `${url}/api/v1/admin/forgot-password`,
  // changePassword: `${url}/api/v1/user/changePassword`,
  resetPassword: `${url}/api/v1/admin/reset-password`,
  changePasswordAdmin: `${url}/api/v1/admin/change-password`,
  // resend: `${url}/api/v1/user/resend`,
  resendOTP: `${url}/api/v1/admin/resendOTP`,
  // profile: `${url}/api/v1/user/profile`,
  getProfile: `${url}/api/v1/admin/view-profile`,
  // editProfile: `${url}/api/v1/user/editProfile`,
  adminEditProfile: `${url}/api/v1/admin/edit-profile`,
  uploadImage: `${url}/api/v1/upload/uploadImage`,

  acceptEmailRequest: `${url}/api/v1/admin/listAllEmailRequest`,
  rejectEmailRequest: `${url}/api/v1/admin/rejectEmailRequest`,
  listAllEmailRequest: `${url}/api/v1/admin/listAllEmailRequest`,
  viewemailreqDetail: `${url}/api/v1/user/viewemailreqDetail`,
  acceptKycRequest: `${url}/api/v1/kyc/acceptKycRequest`,
  rejectKycRequest: `${url}/api/v1/kyc/rejectKycRequest`,
  kyclist: `${url}/api/v1/kyc/listAllKycRequest`,
  viewKyc: `${url}/api/v1/kyc/viewKycDetail`,
  approveOrReject: `${url}/api/v1/admin/kyc/approveOrReject`,
  listUser: `${url}/api/v1/admin/list-user`,
  transactionuserAdmin: `${url}/api/v1/admin/get-user-transactions`,
  transactionAdmin: `${url}/api/v1/admin/list-transaction`,
  adminDashboard: `${url}/api/v1/admin/get-dashboard`,
  viewUser: `${url}/api/v1/admin/get-single-user`,
  createPlan: `${url}/api/v1/plan/create`,
  editPlan: `${url}/api/v1/plan/update-node`,
  viewPlan: `${url}/api/v1/plan/view`,
  Planlist: `${url}/api/v1/plan/list`,
  blockUnblockUser: `${url}/api/v1/admin/block-unblock-user`,
  delete: `${url}/api/v1/plan/delete/`,
  listNodes: `${url}/api/v1/admin/list-nodes`,

  staticData: `${url}/api/v1/statics/get-all`,
  updatestaticData: `${url}/api/v1/statics/update`,
  staticDatavIew: `${url}/api/v1/static/static`,

  listAnnouncement: `${url}/api/v1/announcement/announcementContentList`,
  viewAnnouncement: `${url}/api/v1/announcement/viewannouncementContent`,
  editAnnouncement: `${url}/api/v1/announcement/editannouncementContent`,
  addAnnouncement: `${url}/api/v1/announcement/addannouncementContent`,
  // Create node
  createNode: `${url}/api/v1/admin/create-node`,
  updateNode: `${url}/api/v1/admin/update-node`,

  //user node management
  getAllUserNodes:`${url}/api/v1/admin/get-all-user-nodes`,
  updateValidatorNode:`${url}/api/v1/admin/update-validator-node`
};
export default ApiConfig;
